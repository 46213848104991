import { EllipsisText } from "@components/EllipsisText"
import { useDimensions } from "@hooks/use-dimensions"
import { useResize } from "@hooks/use-resize"
import { useStores } from "@hooks/use-stores"
import { Divider, Image } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React, { memo, useEffect, useRef, useState } from "react"
import { useMedia } from "react-use-media"

import PostComment from "../PostComments"
import PostModal from "../PostModal"
import PostModalVideo from "../postModalVideo"
import Footer from "./components/Footer"
import Header from "./components/Header"
import styles from "./style.module.less"

const PostCard = ({ item, handleLikeUnlike, setRefreshFeed }) => {
  const [visible, setVisible] = useState(false)

  const [videoModalPost, setVideoModalPost] = useState(false)

  const [showComments, setShowComments] = useState(false)
  const [likes, setLikes] = useState(item.likes)
  const [liked, setLiked] = useState(item.liked)
  const [comments, setComments] = useState([])
  const [totalComments, setTotalComments] = useState(Number(item.comments))
  const [isEditing, setIsEditing] = useState(false)

  const videoCover = `${process.env.S3_IMAGES}/${item.urlVideo}`
  const videoCoverUrl =
    videoCover.substr(0, videoCover.lastIndexOf(".")) + ".jpg"

  const { feed, authSite, post } = useStores()
  const {
    getPost,
    postLike,
    postDislike,
    postComment,
    postReplyComment,
    deleteComment,
    deleteReply,
  } = feed

  const { user } = authSite

  const isMobile = useMedia({
    maxWidth: 767,
  })

  const [screenWidth] = useResize()

  const [textRef, update, setUpdate] = useDimensions()

  const handleShowModal = () => {
    if (screenWidth < 768) {
      return
    }

    setVisible(true)
  }

  const handleVideoModal = () => {
    setVideoModalPost(true)
  }

  const handleLikeButton = async (isLiked) => {
    if (isLiked) {
      const { data } = await postDislike({
        postId: item.id,
        affiliatedId: item.affiliatedId,
      })

      if (data.success) {
        setLiked(false)
        setLikes(data.likeCnt)
      }
    } else {
      const { data } = await postLike({
        postId: item.id,
        affiliatedId: item.affiliatedId,
      })

      if (data.success) {
        setLiked(true)
        setLikes(data.likeCnt)
      }
    }
    if (handleLikeUnlike) handleLikeUnlike()
  }

  const onComment = async (values) => {
    const { textComment, commentId, user } = values

    if (!commentId) {
      const { data } = await postComment({
        postId: item.id,
        affiliatedId: item.affiliatedId,
        textComment,
      })
      setComments((prevState) => [
        {
          commentId: data.comment.id,
          textComment: data.comment.textComment,
          commentatorId: data.comment.commentatorId,
          commentatorName: user.name,
          commentatorNickname: user.nickname,
          commentatorPhoto: user.urlProfile || user.urlPhoto,
          replys: [],
        },
        ...prevState,
      ])

      setTotalComments(totalComments + 1)

      return
    }

    if (commentId) {
      const { data } = await postReplyComment({ commentId, textComment })

      setComments(
        _map(comments, (comment) => {
          if (comment.commentId === commentId) {
            return {
              ...comment,
              replys: [
                {
                  idReply: data.comment.id,
                  textComment: data.comment.textComment,
                  commentatorId: data.comment.commentatorId,
                  commentatorUsername: user.username,
                  commentatorName: user.name,
                  commentatorNickname: user.nickname,
                  commentatorPhoto: user.urlProfile || user.urlPhoto,
                },
                ...comment.replys,
              ],
            }
          }

          return comment
        })
      )

      setTotalComments(totalComments + 1)

      return
    }
  }

  const onDeleteComment = async ({ commentId }) => {
    const { data } = await deleteComment({ commentId })

    if (data.success) {
      setComments(comments.filter((comment) => comment.commentId !== commentId))
      setTotalComments(totalComments - 1)
    }
  }

  const onDeleteReply = async ({ commentId, replyId }) => {
    const { data } = await deleteReply({ replyId })

    if (data.success) {
      setComments(
        _map(comments, (comment) => {
          if (comment.commentId === commentId) {
            return {
              ...comment,
              replys: comment.replys.filter(
                (reply) => reply.idReply !== replyId
              ),
            }
          }

          return comment
        })
      )
      setTotalComments(totalComments - 1)
    }
  }

  return (
    <>
      <div
        className={cx(
          "flex flex-column justify-between",
          styles.postCardContainer
        )}
      >
        <>
          <Header
            setRefreshFeed={setRefreshFeed}
            item={item}
            postId={item.id}
            affiliatedId={item.affiliatedId}
            affiliatedUsername={item.affiliatedUsername}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
          {!isEditing && (
            <div
              ref={textRef}
              className={cx("text-post-feed", styles.ellipsisText)}
            >
              <EllipsisText
                postText={item.postText}
                setUpdate={setUpdate}
                update={update}
              />
            </div>
          )}
        </>

        <div className={cx(styles.containerMediaAndFooter)}>
          {item?.urlImage && (
            <Image
              src={`${process.env.S3_IMAGES}/${item.urlImage}`}
              role="button"
              // loading="lazy"
              className={cx("pointer ", styles.imageContainer)}
            />
          )}

          {item?.urlVideo && (
            <div className={styles.videoContainer}>
              <div
                className="postVideoContainer"
                style={{
                  backgroundImage: `url(${videoCoverUrl})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  width: "100%",
                }}
              >
                <img
                  onClick={handleVideoModal}
                  src={require("@images/icons/videoPlayer.svg")}
                />
              </div>
            </div>
          )}
        </div>

        <Footer
          postId={item.id}
          affiliatedId={item.affiliatedId}
          likes={likes}
          liked={liked}
          comments={comments}
          totalComments={totalComments}
          handleLikeButton={() => handleLikeButton(liked)}
          handleCommentsButton={
            isMobile
              ? () => setShowComments(!showComments)
              : () => handleShowModal()
          }
        />

        <Divider className={styles.dividerMargin} />
      </div>

      {showComments && isMobile && (
        <PostComment
          handleCloseModal={() => setShowComments(false)}
          getPost={getPost}
          item={item}
          comments={comments}
          setComments={setComments}
          onComment={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
        />
      )}

      {videoModalPost && (
        <PostModalVideo
          visible={videoModalPost}
          handleCloseModal={() => setVideoModalPost(false)}
          getPost={getPost}
          item={item}
          likes={likes}
          liked={liked}
          handleLikeButton={() => handleLikeButton(liked)}
          comments={comments}
          totalComments={totalComments}
          setComments={setComments}
          onComment={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
        />
      )}

      {visible && (
        <PostModal
          visible={visible}
          handleCloseModal={() => setVisible(false)}
          getPost={getPost}
          item={item}
          likes={likes}
          liked={liked}
          handleLikeButton={() => handleLikeButton(liked)}
          comments={comments}
          totalComments={totalComments}
          setComments={setComments}
          onComment={onComment}
          onDeleteComment={onDeleteComment}
          onDeleteReply={onDeleteReply}
        />
      )}
    </>
  )
}

export default memo(PostCard)
