import translate from "@src/i18n"
import { Avatar, Button } from "antd"
import cx from "classnames"
import React, { useState } from "react"

import styles from "./style.module.less"

const ModelPhotographed = (props) => {
  const {
    handleModelProfile,
    handleFollowers,
    handleUnfollowers,
    model,
    isFavorite,
    handleLikeUnlike,
    type,
    isProfile,
  } = props
  console.log(isProfile)

  const [follow, setFollow] = useState(isFavorite ? true : false)

  const srcProfile = `${process.env.S3_IMAGES}/${model?.urlProfile}`

  const locationFavorite = `${model?.city}, ${model?.state}`

  const followAndUnfollow = async (type) => {
    if (type === "follow") {
      const response = await handleFollowers(model.id)
      if (response) {
        setFollow(true)
      }
    } else if (type === "unfollow") {
      const response = await handleUnfollowers(model.id)
      if (response) {
        setFollow(false)
      }
    }
    isFavorite ? handleLikeUnlike() : null
  }

  return type !== "favorites" ? (
    <div className={styles.rectangle}>
      <div className={cx(styles.rectangule_container)}>
        <div className={cx(styles.block_avatar_name_model)}>
          <Avatar
            src={srcProfile}
            className={cx(styles.photoModel)}
            onClick={(event) => handleModelProfile(event, model.username)}
          />
          <div
            className={styles.nameGrid}
            onClick={(event) => handleModelProfile(event, model.username)}
          >
            <label
              className={
                isProfile ? styles.profile__modelName : styles.modelName
              }
            >
              {!model?.affiliatedNickname
                ? model?.name
                : model?.affiliatedNickname}
            </label>
            <label
              className={
                isProfile ? styles.profile__colorCity : styles.colorCity
              }
            >
              {model?.location || locationFavorite}
            </label>
          </div>
        </div>

        {!follow ? (
          <Button
            className={styles.followBackground}
            onClick={() => followAndUnfollow("follow")}
          >
            {translate("follow")}
          </Button>
        ) : (
          <Button
            className={styles.unfollowBackground}
            onClick={() => followAndUnfollow("unfollow")}
          >
            {translate("following")}
          </Button>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.favorites__rectangle}>
      <div className={styles.favorites__rectangle__content}>
        <Avatar
          src={srcProfile}
          className={styles.favorites__photoModel}
          onClick={(event) => handleModelProfile(event, model.username)}
        />
        <div
          className={styles.favorites__nameGrid}
          onClick={(event) => handleModelProfile(event, model.username)}
        >
          <label className={styles.favorites__modelName}>
            @{model.username}
          </label>
          <label className={styles.favorites__colorCity}>
            {model.location || locationFavorite}
          </label>
        </div>

        {!follow ? (
          <button
            className={styles.favorites__followBackground}
            onClick={() => followAndUnfollow("follow")}
          >
            {translate("follow")}
          </button>
        ) : (
          <button
            className={styles.favorites__unfollowBackground}
            onClick={() => followAndUnfollow("unfollow")}
          >
            {translate("following")}
          </button>
        )}
      </div>
    </div>
  )
}

export default ModelPhotographed
