import { UserOutlined } from "@ant-design/icons"
import { useStores } from "@hooks/use-stores"
import { hoursDiff } from "@utils/date"
import { normalizePath } from "@utils/path"
import { Avatar, Col, Input, Modal } from "antd"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import _get from "lodash/get"
import React, { useState } from "react"
import * as Icon from "react-feather"

import styles from "../style.module.less"

const { TextArea } = Input

const Header = ({
  item,
  isProfile,
  nickname,
  setRefreshFeed,
  isEditing,
  setIsEditing,
}) => {
  const [editText, setEditText] = useState(item.postText)
  const [visible, setVisible] = useState(false)

  const {
    urlProfile,
    affiliated,
    datePublication,
    affiliatedId,
    affiliatedUsername,
    affiliatedNickname,
    postId,
  } = item

  const { authSite, post } = useStores()
  const { deleteSitePost, editSitePost } = post
  const { user } = authSite

  const handleDeletePost = async () => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse item?",
      onOk: async () => {
        await deleteSitePost(item.id)
        setRefreshFeed(true)
      },
      okType: "danger",
      okText: "Sim",
      cancelText: "Não",
    })
  }

  const handleClickPost = (event, id) => {
    const pathUri = normalizePath(`/p/${item.id}`)
    navigate(pathUri)
    event.stopPropagation()
  }

  const handleTextChange = async (event) => {
    setEditText(event.target.value)
  }

  const handleEditPost = async () => {
    const newItem = {
      ...item,
    }

    newItem.postText = editText

    const response = await editSitePost(newItem.id, newItem)
    setIsEditing(false)
    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }
    setRefreshFeed(true)
  }

  const menu = (
    <div className={styles.dropdown__content}>
      {item.affiliatedId === user.id && !isEditing && (
        <button onClick={() => setIsEditing(true)} style={{}}>
          <Icon.Edit size="18" color="#808191" /> Editar post
        </button>
      )}

      {affiliatedId === user.id && (
        <button onClick={handleDeletePost} style={{}}>
          <Icon.Trash size="18" color="#808191" /> Apagar post
        </button>
      )}

      {item.affiliatedId === user.id && (
        <button onClick={handleClickPost}>
          <Icon.Eye size="18" color="#808191" /> Ver Post
        </button>
      )}
    </div>
  )

  const menuViewPost = (
    <div className={styles.dropdown__content}>
      <button onClick={handleClickPost}>
        <Icon.Eye size="18" color="#808191" /> Ver Post
      </button>
    </div>
  )

  const avatarSrc = `${process.env.S3_IMAGES}/${urlProfile}`

  const pageUri = normalizePath(`/perfil/${affiliatedUsername}`)

  return !isProfile ? (
    <>
      <div className={styles.header__post__feed}>
        <div>
          <Link to={pageUri} className={styles.profile__feed__photo}>
            <Avatar size={36} src={avatarSrc} icon={<UserOutlined />} />
          </Link>
          <Link to={pageUri} className={styles.profile__name__link}>
            <span className="f14 fw6 mine-shaft">
              {!affiliatedNickname ? affiliated : affiliatedNickname}
            </span>
          </Link>
        </div>

        <div className="flex items-center ">
          <span className={styles.date__post__feed}>
            {hoursDiff(datePublication, "pt-br", false)}
          </span>
          {item.affiliatedId === user.id ? (
            <div className={styles.dropdown}>
              <button className={styles.dropdown__btn}>. . .</button>
              {menu}
            </div>
          ) : (
            <div className={styles.dropdown}>
              <button className={styles.dropdown__btn}>. . .</button>
              {menuViewPost}
            </div>
          )}
        </div>
      </div>

      {isEditing && (
        <div className={styles.editing__post__area}>
          <TextArea
            onChange={(event) => handleTextChange(event)}
            defaultValue={editText}
            className={styles.input__editing}
          ></TextArea>
        </div>
      )}
      {isEditing && (
        <button
          onClick={handleEditPost}
          style={{}}
          className={styles.btn__editing}
        >
          Atualizar
        </button>
      )}

      {visible && (
        <div>
          <Col key={item?.id} span={24} className="mb12">
            <ModelPost handleClickPost={handleClickPost} />
          </Col>
        </div>
      )}
    </>
  ) : (
    <div className={styles.header__post__feed}>
      <Avatar size={44} src={avatarSrc} icon={<UserOutlined />} />

      <div className="flex flex-column ml8">
        <span className="f14 fw6 mine-shaft">
          {!nickname ? affiliated : nickname}
        </span>
        <span className="f12 scorpion-secondary">
          {hoursDiff(datePublication, "pt-br", false)}
        </span>

        <Icon.Layout size="20" color="#808191" />
      </div>
    </div>
  )
}

export default Header
