// import PostCard from "@components/PostCard"
import PostCard from "@components/PostCardAnonimatta"
import EmptyEssay from "@modules/Essay/Site/components/EmptyEssay"
import _map from "lodash/map"
import React from "react"
import Mansory, { ResponsiveMasonry } from "react-responsive-masonry"

const PostFeed = ({
  data,
  handleLikeUnlike,
  setRefreshFeed,
  isFeed,
  isPost,
}) => {
  if (data && !data.length) {
    return (
      <div className="mb48">
        <EmptyEssay isPost={isPost} isFeed={isFeed} />
      </div>
    )
  }

  return (
    <>
      <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 767: 1, 1023: 1 }}>
        <Mansory gutter="24">
          {_map(data, (item) => {
            return (
              <PostCard
                setRefreshFeed={setRefreshFeed}
                key={item.id}
                item={item}
                handleLikeUnlike={handleLikeUnlike}
              />
            )
          })}
        </Mansory>
      </ResponsiveMasonry>
    </>
  )
}

export default PostFeed
