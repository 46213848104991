// import ModelPhotographed from "@components/modelPhotographed"
import ModelPhotographed from "@components/ModelPhotographedAnonimatta"
import { useStores } from "@hooks/use-stores"
import { normalizePath } from "@utils/path"
import { Col, Row } from "antd"
import { navigate } from "gatsby"
import _map from "lodash/map"
import React, { useEffect, useState } from "react"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

let defaultWidth

if (typeof window !== `undefined`) {
  defaultWidth = window.innerWidth
}

const RecommendedProfiles = (props) => {
  const { profileData, handleMostFollowed } = props
  const [profilesModelsNew, setProfilesModelsNew] = useState([])
  const [dimensions, setDimensions] = useState({ windowWidth: defaultWidth })

  const { feed } = useStores()
  const { postNewFollow, deletedFollowers } = feed

  const tabletFull = useMedia({ minWidth: 768, maxWidth: 1023 })

  useEffect(() => {
    handleDisplayModels()
    handleDimensionScreen()
  }, [profileData, dimensions])

  const handleDisplayModels = () => {
    if (profileData === null) {
      return
    }

    const profilesModelsRandom = profileData.slice()
    const newResponse = []

    if (dimensions.windowWidth < 768) {
      for (let i = 0; i < 3; i++) {
        const arrayRandom = profilesModelsRandom[i]
        newResponse.push(arrayRandom)
        setProfilesModelsNew(newResponse)
      }
    } else if (dimensions.windowWidth >= 768) {
      for (let i = 0; i < 6; i++) {
        const arrayRandom = profilesModelsRandom[i]
        newResponse.push(arrayRandom)
        setProfilesModelsNew(newResponse)
      }
    }
  }

  const handleFollowers = async (id) => {
    const { data } = await postNewFollow(id)

    if (data.success === true) {
      setTimeout(() => {
        handleMostFollowed()
      }, 3000)
      return true
    }
    return false
  }

  const handleUnfollowers = async (id) => {
    const { data } = await deletedFollowers(id)

    if (data.success === true) {
      handleMostFollowed()
      return true
    }
    return false
  }

  const handleDimensionScreen = () => {
    const handler = () => setDimensions({ windowWidth: window.innerWidth })

    window.addEventListener(`resize`, handler)
    return () => window.removeEventListener(`resize`, handler)
  }

  const handleModelProfile = (event, username) => {
    const pageUri = normalizePath(`/perfil/${username}`)
    navigate(pageUri)
    event.stopPropagation()
  }

  const showModelsTitle = () => {
    console.log("lista ", profileData)
    if (!profileData || profileData.length === 0) {
      return <div></div>
    } else {
      return (
        <div className={styles.recommended__title}>
          <h3>Recomendados</h3>
        </div>
      )
    }
  }

  return (
    <div className={styles.recommended}>
      {showModelsTitle()}

      <Row className={styles.paddingList}>
        {_map(profilesModelsNew, (item) => {
          if (item !== undefined) {
            return (
              <>
                <Col key={item?.id} span={24} className="mb12">
                  <ModelPhotographed
                    model={item}
                    handleUnfollowers={handleUnfollowers}
                    handleFollowers={handleFollowers}
                    handleModelProfile={handleModelProfile}
                    isProfile={props.isProfile}
                  />
                </Col>
              </>
            )
          }
        })}
      </Row>
    </div>
  )
}

export default RecommendedProfiles
