import { Icon } from "@components/Icons"
import translate from "@src/i18n"
import { Button, Tabs } from "antd"
import cx from "classnames"
import _map from "lodash/map"
import React from "react"

import styles from "./style.module.less"

const { TabPane } = Tabs

const CustomFilterbar = ({
  handleChangeTab,
  filters,
  activeTab,
  className,
}) => {
  return (
    <>
      <Tabs
        className={className}
        onChange={(activeTab) => handleChangeTab(activeTab)}
        activeKey={activeTab}
      >
        <TabPane tab={translate("all")} key={"all"} />

        {_map(filters, (item) => {
          return <TabPane tab={translate(item)} key={item} />
        })}
      </Tabs>
    </>
  )
}

export default CustomFilterbar
